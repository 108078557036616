var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "page-system-backups"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.globalErrorMessage ? _c('v-alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.globalErrorMessage) + " ")]) : _vm._e()], 1), _c('v-card', [_c('v-card-title', {
    staticClass: "mt-5"
  }, [_c('v-text-field', {
    attrs: {
      "append-icon": _vm.downloadToken === '' ? 'fas fa-unlock' : '',
      "clear-icon": _vm.downloadToken !== '' ? 'fas fa-times' : '',
      "clearable": "",
      "autocomplete": "off",
      "type": "password",
      "outlined": "",
      "label": _vm.$t('TokenDownloadKey'),
      "title": _vm.downloadToken === '' ? _vm.$t('UnlockDownload') : _vm.$t('Clear')
    },
    on: {
      "click:append": _vm.unlockDownload,
      "click:clear": _vm.clearTokenAndLock,
      "keypress": function ($event) {
        _vm.downloadToken = '';
      }
    },
    model: {
      value: _vm.downloadTokenTmp,
      callback: function ($$v) {
        _vm.downloadTokenTmp = $$v;
      },
      expression: "downloadTokenTmp"
    }
  }), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('a', {
    ref: "downloadFileButton",
    staticClass: "d-none",
    attrs: {
      "href": _vm.partFileDataURL,
      "download": _vm.partFileName
    }
  }), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.backups,
      "search": _vm.search,
      "loading": _vm.loadingTable,
      "custom-filter": _vm.customFilter,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.created`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long")) + " ")];
      }
    }, {
      key: `item.size`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.formatBytes(item.size)) + " ")];
      }
    }, {
      key: `item.action`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "title": _vm.$t('Download'),
            "disabled": _vm.downloadToken === '' ? true : false,
            "loading": _vm.downloading
          },
          on: {
            "click": function ($event) {
              return _vm.downloadBackup(item._id);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-download ")])], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }