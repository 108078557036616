<template lang="html" src="./pageSystemBackups.template.vue"></template>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSystemBackups.i18n');

export default {
  name: 'PageSystemBackups',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loadingTable: false,
      backups: [],
      globalErrorMessage: null,
      search: '',
      headers: null,
      downloading: false,
      downloadToken: '',
      downloadTokenTmp: '',
      partFileDataURL: null,
      partFileName: null,
    };
  },
  created() {
    this.headers = [
      { text: this.$t('created'), value: 'created' },
      { text: this.$t('size'), value: 'size' },
      { text: this.$t('action'), value: 'action' },
    ];
    this.backupList();
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      if (item.created) {
        if (this.$d(new Date(item.created), 'long').toString().includes(search)) return true;
      }
      if(item.size) {
        if(this.formatBytes(item.size).includes(search)) return true;
      }
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    backupList() {
      /**
       * GET ALL BACKUPS
       */
      this.loadingTable = true;
      this.$apiInstance
        .backupList()
        .then(
          (data) => {
            this.backups = data;
          },
          (error) => {
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
        });
    },
    unlockDownload() {
      this.downloadToken = this.downloadTokenTmp;
    },
    clearTokenAndLock() {
      this.downloadTokenTmp = '';
      this.downloadToken = '';
    },
    downloadBackup(backupUUID) {
      this.downloading = true;
      this.partFileDataURL = null;
      this.partFileName = null;

      let xhttpError = false;
      const xhttpUrl = `${this.$apiInstance.apiClient.basePath}/internal/backup/${backupUUID}?token=${this.downloadToken}`;
      const oReq = new XMLHttpRequest();
      oReq.open('GET', xhttpUrl, true);
      oReq.responseType = 'arraybuffer';
      oReq.setRequestHeader(
        'Authorization',
        'Bearer ' + this.$apiInstance.apiClient.authentications.JWTAuth.accessToken
      );

      oReq.onerror = function(error) {
        xhttpError = true;
      };
      oReq.onaboart = function(error) {
        xhttpError = true;
      };
      oReq.ontimeout = function(error) {
        xhttpError = true;
      };


      oReq.onloadend = function() {
        if(oReq.status !== 200 || xhttpError) {
          this.downloading = false;
          this.$notification.notify('ERROR', oReq.statusText);
        }else{
          const byteArray = new Uint8Array(oReq.response);
          const blob = new Blob([byteArray], {
            type: 'application/octet-stream',
          });
          this.downloading = false;
          this.partFileDataURL = URL.createObjectURL(blob);
          this.partFileName = `${backupUUID}.tar`;
          setTimeout(() => {
            this.$refs.downloadFileButton.click();
          }, 250);
        }
      }.bind(this);
      oReq.send();
    },
  },
};
</script>
